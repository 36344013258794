import Vue from 'vue'
import { cpf, cnpj } from 'cpf-cnpj-validator'

function isValid(cpfCnpj) {
  const document = String(cpfCnpj).replace(/[^\d]+/g, '')

  if (String(document).length === 11) {
    return cpf.isValid(document)
  }

  return cnpj.isValid(document)
}

function toMask(cpfCnpj) {
  if (String(cpfCnpj).length === 11) {
    return cpfCnpj.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4',
    )
  }

  return cpfCnpj.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  )
}

Vue.prototype.$cpfCnpj = {
  isValid,
  toMask,
}
