import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async () => {
  try {
    const { data } = await axios.get('file')

    if (data?.files) {
      return data.files
    }

    return []
  } catch (err) {
    return err
  }
}

export const findById = async (_, id) => {
  try {
    const { data } = await axios.get(`file/${id}`)

    if (data) {
      return data
    }

    return null
  } catch (err) {
    return err
  }
}

export const create = async (_, file) => {
  Vue.prototype.$loading(true)

  try {
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await axios.post('upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (data?.fileId) {
      return data.fileId
    }

    return null
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
