import { unauthenticated } from './utils'

export default [
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/admin/hirePlan/index.vue'),
    path: '/admin/hire-plan',
    name: 'adminHirePlan',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/admin/walletExtract/index.vue'),
    path: '/admin/wallet-extract',
    name: 'adminWalletExtract',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/admin/user/index.vue'),
    path: '/admin/user',
    name: 'adminUser',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/admin/levels/index.vue'),
    path: '/admin/levels',
    name: 'adminLevels',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/admin/taxes/index.vue'),
    path: '/admin/tax',
    name: 'adminTaxes',
  },
]
