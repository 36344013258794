const authenticated = (_to, _from, next) => (localStorage.getItem('token')
  ? next('/')
  : next())

const unauthenticated = (_to, _from, next) => (localStorage.getItem('token')
  ? next()
  : next('/signin'))

export {
  authenticated,
  unauthenticated,
}
