import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('customer-level', {
      params: filter,
    })

    if (data?.customerLevels) {
      commit('setRows', data.customerLevels)

      return data.customerLevels
    }

    return null
  } catch (err) {
    return err
  }
}

export const findById = async ({ commit }, id) => {
  try {
    const { data } = await axios.get(`customer-level/${id}`)

    if (data?.customerLevel) {
      commit('setRow', data.customerLevel)

      return data.customerLevel
    }

    return null
  } catch (err) {
    return err
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.post('customer-level', body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const update = async ({ state }, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.put(`customer-level/${state.row.id}`, body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const remove = async (_, id) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.delete(`customer-level/${id}`)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
