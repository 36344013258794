import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('report/approve-campaign-sms', {
      params: filter,
    })

    if (data?.records) {
      commit('setRows', data.records)

      return data.records
    }

    return null
  } catch (err) {
    return err
  }
}

export const approve = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    await axios.put(`sms-template/approve/${body.id}`, {
      serviceIds: body.serviceIds,
    })

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const refuse = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    await axios.put(`sms-template/refuse/${body.id}`, {
      observation: body.observation,
    })

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
