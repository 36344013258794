import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('affiliate-commission', {
      params: filter,
    })

    if (data?.affiliateCommissions) {
      commit('setRows', data.affiliateCommissions)

      return data.affiliateCommissions
    }

    return null
  } catch (err) {
    return err
  }
}

export const findById = async ({ commit }, id) => {
  try {
    const { data } = await axios.get(`affiliate-commission/${id}`)

    if (data?.affiliateCommission) {
      commit('setRow', data.affiliateCommission)

      return data.affiliateCommission
    }

    return null
  } catch (err) {
    return err
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.post('affiliate-commission', body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const update = async ({ state }, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.put(`affiliate-commission/${state.row.id}`, body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const remove = async (_, id) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.delete(`affiliate-commission/${id}`)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
