import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('customer-tax', {
      params: filter,
    })

    if (data?.customerTaxes) {
      commit('setRows', data.customerTaxes)

      return data.customerTaxes
    }

    return null
  } catch (err) {
    return err
  }
}

export const findById = async ({ commit }, id) => {
  try {
    const { data } = await axios.get(`customer-tax/${id}`)

    if (data?.customerTax) {
      commit('setRow', data.customerTax)

      return data.customerTax
    }

    return null
  } catch (err) {
    return err
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.post('customer-tax', body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const update = async ({ state }, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.put(`customer-tax/${state.row.id}`, body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const remove = async (_, id) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.delete(`customer-tax/${id}`)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const clearCustomerTaxes = async ({ commit }) => {
  commit('setRows', [])
}
