import Vue from 'vue'
import axios from '@/plugins/axios'

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('wallet', body)

    return status
  } catch (error) {
    return error
  } finally {
    Vue.prototype.$loading(false)
  }
}
