<template>
  <component :is="resolveLayout">
    <router-view />
    <loading />
    <notify />
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import Loading from '@/components/globals/Loading.vue'
import Notify from '@/components/globals/Notify.vue'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import { useRouter } from '@/utils'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    Loading,
    Notify,
  },
  created() {
    const theme = localStorage.getItem('dark_theme')
    if (theme) {
      if (theme === 'true') {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    } else {
      this.$vuetify.theme.dark = true
      localStorage.setItem(
        'dark_theme',
        this.$vuetify.theme.dark.toString(),
      )
    }
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      if (route.value.name === null) return null
      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  methods: {
    resize() {
      const theme = localStorage.getItem('dark_theme')
      let zoom = 1
      const width = 100 / zoom
      const height = 100 / zoom

      zoom -= 0.25
      document.body.style.transformOrigin = 'left top'
      document.body.style.transform = `scale(${zoom})`
      document.body.style.width = `${width}%`
      document.body.style.height = `${height}%`
      document.body.style.backgroundColor = theme === 'true' ? '#202124' : 'white'
      document.body.style.position = 'absolute'
    },
  },
}
</script>

<style>
  html {
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
