import Vue from 'vue'

/* eslint-disable quote-props */
function replaceSpecialCharacters(str) {
  const caracteresEspeciais = {
    'á': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o',
    'ú': 'u',
    'à': 'a',
    'è': 'e',
    'ì': 'i',
    'ò': 'o',
    'ù': 'u',
    'ã': 'a',
    'õ': 'o',
    'â': 'a',
    'ê': 'e',
    'î': 'i',
    'ô': 'o',
    'û': 'u',
    'ç': 'c',
  }

  return str.replace(/[áéíóúàèìòùãõâêîôûç]/g, matched => caracteresEspeciais[matched])
}

Vue.prototype.$utils = {
  replaceSpecialCharacters,
}
