import '@/plugins'
import '@/styles/styles.scss'
import '@/plugins/vue-carousel'
import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

Vue.component('Date', () => import('@/components/globals/Date.vue'))
Vue.component('DialogForm', () => import('@/components/globals/DialogForm.vue'))
Vue.component('DialogRemove', () => import('@/components/globals/DialogRemove.vue'))
Vue.component('Loading', () => import('@/components/globals/Loading.vue'))
Vue.component('Notify', () => import('@/components/globals/Notify.vue'))

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
