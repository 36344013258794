import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('sms-template', {
      params: filter,
    })

    if (data?.smsTemplates) {
      commit('setRows', data.smsTemplates)

      return data.smsTemplates
    }

    return null
  } catch (err) {
    return err
  }
}

export const findById = async ({ commit }, id) => {
  try {
    const { data } = await axios.get(`sms-template/${id}`)

    if (data?.smsTemplate) {
      commit('setRow', data.smsTemplate)

      return data.smsTemplate
    }

    return null
  } catch (err) {
    return err
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.post('sms-template', body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const update = async ({ state }, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.put(`sms-template/${state.row.id}`, body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const remove = async (_, id) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.delete(`sms-template/${id}`)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
