import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { pt } from 'vuetify/lib/locale'
import preset from './default-preset/preset'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    dark: false,
    options: {
      customProperties: false,
      variations: false,
    },
  },
})
