import Vue from 'vue'
import InputFacade from 'vue-input-facade'
import money from 'v-money'

const options = {
  name: 'mask',
  tokens: {
    '#': { pattern: /\d/ },
    A: { pattern: /[a-z]/i },
    N: { pattern: /[0-9a-z]/i },
    H: { pattern: /[0-9a-f]/i, transform: v => (typeof v === 'string' ? v.toLocaleUpperCase() : v) },
    X: { pattern: /./ },
  },
}

Vue.use(InputFacade, options)
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 3,
  masked: false,
})

Vue.prototype.$money = (value, digits = 2) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    return value
  }

  return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: digits })
}

Vue.prototype.$strip = value => Number(value.replace(/[.]/g, '').replace(',', '.').trim())
