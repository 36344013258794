require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#006064',
        secondary: '#263238',
        accent: '#327db8',
        success: '#00C853',
        info: '#0091EA',
        warning: '#FFB400',
        error: '#B71C1C',
      },
      dark: {
        primary: '#06F11AB0',
        secondary: '#263238',
        accent: '#327db8',
        success: '#00C853',
        info: '#0091EA',
        warning: '#FFB400',
        error: '#B71C1C',
      },
    },
  },
}
