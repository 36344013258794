<template>
  <v-snackbar
    v-model="visible"
    :color="notify.color"
    :timeout="notify.time"
    app
    :multi-line="true"
    style="z-index: 1000 !important;"
    top
  >
    <v-row>
      <v-col
        cols="1"
        class="align-self-center"
      >
        <v-icon
          dark
          size="30"
          left
        >
          {{ icons[notify.color] }}
        </v-icon>
      </v-col>
      <v-col cols="10">
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="font-weight-semibold"
          v-html="notify.message"
        ></span>
        <!-- eslint-enable vue/no-v-html -->
      </v-col>
    </v-row>

    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        fab
        text
        v-bind="attrs"
        @click="visible = false"
      >
        <v-icon
          dark
        >
          {{ icons.close }}
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import {
  mdiAlert,
  mdiAlertOutline,
  mdiBell,
  mdiCheckCircle,
  mdiClose,
  mdiInformation,
} from '@mdi/js'
import { mapMutations, mapState } from 'vuex'

export default {
  setup() {
    return {
      icons: {
        close: mdiClose,
        error: mdiAlert,
        grey: mdiBell,
        info: mdiInformation,
        success: mdiCheckCircle,
        warning: mdiAlertOutline,
      },
    }
  },

  computed: {
    ...mapState('app', [
      'notify',
    ]),

    visible: {
      get() {
        return this.notify.visible
      },
      set(valor) {
        if (!valor) {
          this.setNotify({
            visible: false,
            color: null,
            icon: null,
            message: null,
            time: null,
          })
        }
      },
    },
  },

  watch: {
    'notify.message': {
      handler() {
        if (this.notify.message) {
          this.notify.message = this.notify.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('app', [
      'setNotify',
    ]),
  },
}
</script>
