import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('report/commission', {
      params: filter,
    })

    if (data?.records) {
      commit('setRows', data)

      return data
    }

    return null
  } catch (err) {
    return err
  }
}

export const getUsersByAffiliate = async (_, filter) => {
  try {
    const { data } = await axios.get('affiliate', {
      params: filter,
    })

    if (data?.affiliates) {
      return data.affiliates
    }

    return []
  } catch (err) {
    return err
  }
}
