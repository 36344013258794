import { unauthenticated } from './utils'

export default [
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/affiliates/index.vue'),
    path: '/affiliates',
    name: 'affiliates',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/affiliate-comissions/index.vue'),
    path: '/affiliate-comissions',
    name: 'affiliatesComission',
  },
]
