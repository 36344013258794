import { unauthenticated } from './utils'

export default [
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/serviceSms/index.vue'),
    path: '/service/sms',
    name: 'serviceSms',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/serviceVoice/index.vue'),
    path: '/service/voice',
    name: 'serviceVoice',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/serviceSanitizer/index.vue'),
    path: '/service/sanitizer',
    name: 'serviceSanitizer',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/serviceNumberGenerator/index.vue'),
    path: '/service/number-generator',
    name: 'serviceNumberGenerator',
  },
]
