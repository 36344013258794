import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async (_, filter) => {
  try {
    const { data } = await axios.get('wallet-extract', {
      params: filter,
    })

    if (data) {
      return data
    }

    return null
  } catch (error) {
    return error
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('wallet-maintenance', body)

    if (status) {
      return status
    }

    return null
  } catch (error) {
    return error
  } finally {
    Vue.prototype.$loading(false)
  }
}
