import Vue from 'vue'
import axios from '@/plugins/axios'

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.post('order', body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
