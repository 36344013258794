<template>
  <v-dialog
    v-model="loading"
    persistent
    width="300"
  >
    <v-card
      color="white"
    >
      <v-card-text>
        Aguarde...
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', [
      'loading',
    ]),
  },
}
</script>
