import Vue from 'vue'
import axios from '@/plugins/axios'

export const findById = async (_, id) => {
  try {
    const { data } = await axios.get(`campaign-whatsapp/${id}`)

    return data
  } catch (err) {
    return err
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    const { status } = await axios.post('campaign-whatsapp', body)

    return status
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
