import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('affiliate-commission-custom', {
      params: filter,
    })

    if (data?.affiliateCommissionCustoms) {
      commit('setRows', data.affiliateCommissionCustoms)

      return data.affiliateCommissionCustoms
    }

    return null
  } catch (err) {
    return err
  }
}

export const findById = async ({ commit }, id) => {
  try {
    const { data } = await axios.get(`affiliate-commission-custom/${id}`)

    if (data?.affiliateCommissionCustom) {
      commit('setRow', data.affiliateCommissionCustom)

      return data.affiliateCommissionCustom
    }

    return null
  } catch (err) {
    return err
  }
}

export const create = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.post('affiliate-commission-custom', body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const update = async ({ state }, body) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.put(`affiliate-commission-custom/${state.row.id}`, body)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const remove = async (_, id) => {
  Vue.prototype.$loading(true)

  try {
    return await axios.delete(`affiliate-commission-custom/${id}`)
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
