import { unauthenticated } from './utils'

export default [
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/account/hirePlan/index.vue'),
    path: '/account/hire-plan',
    name: 'accountHirePlan',
  },
  {
    beforeEnter: unauthenticated,
    component: () => import('@/views/account/walletExtract/index.vue'),
    path: '/account/wallet-extract',
    name: 'accountWalletExtract',
  },
]
