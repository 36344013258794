import axios from '@/plugins/axios'

export const find = async (_, filter) => {
  try {
    const { data } = await axios.get('service', {
      params: filter,
    })

    if (data?.services) {
      return data.services
    }

    return null
  } catch (err) {
    return err
  }
}
