import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('report/services', {
      params: filter,
    })

    if (data?.records) {
      commit('setRows', data.records)

      return data.records
    }

    return null
  } catch (err) {
    return err
  }
}

export const getSmsPhones = async (_, campaignId) => {
  Vue.prototype.$loading(true)

  try {
    const response = await axios.get(`report/sms-phones/${campaignId}`, {
      responseType: 'blob',
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${campaignId}.xlsx`)
    document.body.appendChild(link)
    link.click()

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const getWhatsAppPhones = async (_, campaignId) => {
  Vue.prototype.$loading(true)

  try {
    const response = await axios.get(`report/whatsapp-phones/${campaignId}`, {
      responseType: 'blob',
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${campaignId}.csv`)
    document.body.appendChild(link)
    link.click()

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const getVoicePhones = async (_, campaignId) => {
  Vue.prototype.$loading(true)

  try {
    const response = await axios.get(`report/voice-phones/${campaignId}`, {
      responseType: 'blob',
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${campaignId}.xlsx`)
    document.body.appendChild(link)
    link.click()

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
export const updateCampaign = async (_, campaign) => {
  try {
    await axios.put(`campaign/${campaign.id}`, campaign)

    return true
  } catch (err) {
    return err
  }
}
export const cancellCampaign = async (_, campaignId) => {
  try {
    await axios.post(`campaign/${campaignId}/cancel`)

    return true
  } catch (err) {
    return err
  }
}
