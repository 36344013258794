import * as VeeValidate from 'vee-validate'
import {
  confirmed,
  email,
  max,
  min,
  numeric,
  required,
} from 'vee-validate/dist/rules'
import Vue from 'vue'
import br from 'vee-validate/dist/locale/pt_BR'
import dayjs from 'dayjs'

VeeValidate.extend('confirmed', confirmed)
VeeValidate.extend('dataValida', {
  validate(value) {
    let tipo

    if (value.length === 7) tipo = 'MM/YYYY'
    else if (value.length === 10) tipo = 'DD/MM/YYYY'
    else if (value.length === 19) tipo = 'DD/MM/YYYY HH:mm:ss'

    return Vue.prototype.$day(value, tipo).format(tipo) === value
  },
  message: '{_field_} inválida',
})
VeeValidate.extend('email', email)
VeeValidate.extend('max', max)
VeeValidate.extend('min', min)
VeeValidate.extend('numeric', numeric)
VeeValidate.extend('required', required)

const validate = (value, args) => dayjs(value, 'DD/MM/YYYY HH:mm').diff(dayjs(args.min, 'DD/MM/YYYY')) > 0
const validateValue = (value, args) => Number(value.replace(/[.]/g, '').replace(',', '.').trim()) >= args.min
const params = [
  {
    name: 'min',
  },
]

VeeValidate.extend('date_min', {
  validate,
  params,
})

VeeValidate.extend('validate_value', {
  validate: validateValue,
  params,
})

VeeValidate.extend('url', {
  validate: value => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' // protocolo
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domínio
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // endereço IP (v4)
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // porta e caminho
      + '(\\?[;&a-z\\d%_.~+=-]*)?' // string de consulta
      + '(\\#[-a-z\\d_]*)?$', 'i') // fragmento

    return !!urlPattern.test(value)
  },
  message: 'Por favor, insira uma URL válida',
})

VeeValidate.localize('pt_BR',
  {
    ...br,
    messages: {
      ...br.messages,
      date_min: 'O campo {_field_} deve ser maior que {min}',
      validate_value: 'O campo {_field_} deve ser maior que {min}',
    },
  })
VeeValidate.setInteractionMode('passive')

Vue.component('ValidationObserver', VeeValidate.ValidationObserver)
Vue.component('ValidationProvider', VeeValidate.ValidationProvider)
