import Vue from 'vue'
import store from '@/store'

const notify = (message, type) => {
  store.commit('app/setNotify', {
    visible: false,
    color: '',
    message: '',
    time: -1,
  })

  let color = ''

  switch (type) {
    case 'success':
      color = 'success'
      break

    case 'info':
      color = 'info'
      break

    case 'warning':
      color = 'warning'
      break

    case 'error':
      color = 'error'
      break

    default:
      color = 'secondary'
      break
  }

  setTimeout(() => {
    store.commit('app/setNotify', {
      visible: true,
      color,
      message,
      time: 5000,
    })
  }, 300)
}

Vue.prototype.$notify = notify
