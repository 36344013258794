<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen" />

    <v-app-bar
      app
      color="bg-app-bar"
      clipped-left
      dark
      dense
    >
      <v-app-bar-nav-icon
        class="d-block ml-0"
        @click="isDrawerOpen = !isDrawerOpen"
      />
      <v-toolbar-title class="white--text">
        <v-img
          contain
          max-width="90"
          class="greeting-card-trophy"
          src="@/assets/images/logos/logo.png"
        ></v-img>
      </v-toolbar-title>
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-spacer />

          <label class="switch-dark-mode">
            <input
              class="toggle-checkbox"
              type="checkbox"
              :value="darkMode"
              :checked="darkMode"
              @change="toggleDarkMode"
            />
            <div class="toggle-slot">
              <div class="sun-icon-wrapper">
                <v-icon class="sun-icon">
                  {{ icons.mdiWhiteBalanceSunny }}
                </v-icon>
              </div>
              <div class="toggle-button"></div>

              <div class="moon-icon-wrapper">
                <v-icon class="moon-icon">
                  {{ icons.mdiWeatherNight }}
                </v-icon>
              </div>
            </div>
          </label>
          <app-bar-user-menu />
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <v-btn
          color="success"
          x-large
          class="v-btn--example"
          absolute
          title="Suporte"
          href="https://wa.me/5511941617953"
          target="_blank"
        >
          <v-icon
            size="30"
            left
          >
            {{ icons.mdiWhatsapp }}
          </v-icon>
          Suporte
        </v-btn>
        <slot />
      </div>
    </v-main>

    <v-footer
      absolute
      app
      class="px-0"
      color="transparent"
      height="56"
      inset
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span> &copy; 2024 - <b>Strong Expert</b> </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiWhiteBalanceSunny, mdiWeatherNight, mdiWhatsapp } from '@mdi/js'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import VerticalNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'

export default {
  components: {
    AppBarUserMenu,
    VerticalNavMenu,
  },
  created() {
    if (localStorage.getItem('dark_theme') === 'true') this.darkMode = !this.darkMode
  },
  setup() {
    const isDrawerOpen = ref(null)
    const darkMode = ref(false)

    function toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.darkMode = !this.darkMode
      localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString())
      document.body.style.backgroundColor = this.darkMode ? '#202124' : 'white'
    }

    return {
      isDrawerOpen,
      toggleDarkMode,
      darkMode,
      icons: {
        mdiWhiteBalanceSunny,
        mdiWeatherNight,
        mdiWhatsapp,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  margin-left: auto;
  margin-right: auto;
}

.bg-app-bar {
  background: linear-gradient(90deg, #06f11ab0 0%, #0ce339ab 20%, #24bb9ac7 44%, #369de3bf 66%, #3899ebc2 100%);
  background-color: white !important;
}

.switch-dark-mode {
  margin-right: 20px;
}

.toggle-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-slot {
  position: relative;
  height: 2.5em;
  width: 4.5em;
  border: 5px solid #e4e7ec;
  border-radius: 10em;
  background-color: white;
  transition: background-color 250ms;
}

.toggle-checkbox:checked ~ .toggle-slot {
  background-color: #374151;
}

.toggle-button {
  transform: translate(34px, 3px);
  position: absolute;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background-color: #ffeccf;
  box-shadow: inset 0px 0px 0px 2.5px #ffbb52;
  transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
  background-color: #485367;
  box-shadow: inset 0px 0px 0px 2.5px white;
  transform: translate(5px, 3px);
}

.sun-icon {
  position: absolute;
  height: 6em;
  width: 6em;
  color: #ffbb52;
}

.sun-icon-wrapper {
  position: absolute;
  height: 6em;
  width: 6em;
  opacity: 1;
  transform: translate(-48px, -62px) rotate(15deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
  opacity: 0;
  transform: translate(-28px, -62px) rotate(0deg);
}

.moon-icon {
  position: absolute;
  height: 6em;
  width: 6em;
  color: white;
}

.moon-icon-wrapper {
  position: absolute;
  height: 6em;
  width: 6em;
  opacity: 0;
  transform: translate(-48px, -62px) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(0.26, 2.5, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
  opacity: 1;
  transform: translate(-30px, -51px) rotate(-15deg);
}

.theme--dark.v-application {
  .bg-app-bar {
    background: #60bdd314;
    background-color: white !important;
    border-bottom: 1px solid #3b3c3f;
  }
  span, h1, h2, h3, h4, h5, h6, p {
    transform: scale(.9)
  }
}

.v-btn--example {
  bottom: 40px;
  right: 20px;
  position: fixed;
  z-index: 9999;
  text-transform: none;
}
</style>
