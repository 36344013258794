export default {
  isLoading(state, status) {
    state.loading = status
  },

  setNotify(state, {
    visible,
    color,
    message,
    time,
  }) {
    state.notify = {
      visible,
      color,
      message,
      time,
    }
  },
}
