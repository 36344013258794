import Vue from 'vue'
import axios from '@/plugins/axios'

export const find = async ({ commit }, filter) => {
  try {
    const { data } = await axios.get('report/approve-campaign-voice', {
      params: filter,
    })

    if (data?.records) {
      commit('setRows', data.records)

      return data.records
    }

    return null
  } catch (err) {
    return err
  }
}

export const approve = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    await axios.put('/campaign-voice/approve', body)

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}

export const refuse = async (_, body) => {
  Vue.prototype.$loading(true)

  try {
    await axios.put('/campaign-voice/refuse', body)

    return true
  } catch (err) {
    return err
  } finally {
    Vue.prototype.$loading(false)
  }
}
