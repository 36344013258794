export const ROLE_TYPE = {
  admin: 1,
  client: 2,
}

export default {
  setRole(state, role) {
    state.isAdmin = Object.values(ROLE_TYPE).includes(role)
  },
}
